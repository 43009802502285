import React from 'react';
import Home from './components/Home/Home';
import {Route, Routes} from 'react-router-dom'
import './App.css';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home/>}/>
            </Routes>
        </div>
    );
}

export default App;
