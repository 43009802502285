import React, {useEffect, useState} from "react";
import Navbar from "../Navbar/Navbar";
import Table from "react-bootstrap/Table";
import Footer from "../Footer/Footer";
import Timetable from "../Timetable/Timetable";
import dot from "../../assests/red_dot.svg";
// import biglogo from "../../assests/biglogo.svg";
import biglogo from "../../assests/base_tm_logo.png";
import axios from "axios";
import "../TodayTable/TodayTable.css";

import * as timeClass from "../../utils/TimeClass";
import "./Home.css";

const Home = () => {
    const [num, setNum] = useState([]);

    useEffect(() => {
        async function getData() {
            const res = await axios.get("https://api.cashfatafat.in/api/web/getResults");
            return res.data.data;
        }

        getData().then(data => {
            setNum(data);
        });
    }, []);

    return (<>
        <Navbar/>
        <div className="result__dashboard section__padding">
            <div className="dashboard-title">
                <div className="dot">
                    <img src={dot} alt=""/>
                </div>
                <div className="live">
                    <div className="marquee_container">
                        <p className="marquee_tag">
                            LIVE
                        </p>
                    </div>
                </div>
                <div className="live_side">
                    <a href="https://cashfatafat.com" rel="noreferrer" target="_blank"><h2>Official Website</h2></a>
                </div>
            </div>
            {/* TODAY RESULT */}
            {num.length ? num.map((element) => element.data.length ? timeClass.isToday(element.data[0].created_at) === true ?
                <div className="main_table">
                    <div className="table__title">
                        <h3>{element.title}</h3>
                    </div>
                    <Table striped bordered hover size="sm" responsive="sm">
                        <thead>
                        <tr>
                            <th>Time</th>
                            {element.data.length ? element.data.map((element2) => {
                                return <th key={element2.id}>
                                    {element2.start_time} - {element2.end_time}
                                </th>
                            }) : null}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Lucky Number</td>
                            {element.data.length ? element.data.map((element2) => {
                                return <td key={element2.id}>{element2.lucky_number} </td>
                            }) : null}
                        </tr>
                        </tbody>
                    </Table>
                </div> : null : null) : null}
            {/* END TODAY RESULT  */}
            <div className="description">
                <div className="banner">
                    <p>
                        Today Live Fast Result ❤️ Cash Fatafat Live Results ❤️ कैश फटाफट
                        ❤️ ক্যাশ ফটাফট। old and new results
                    </p>
                    <p className="marquee_tag" style={{color: "red"}}>Help line:- 98313-25333</p>
                </div>
                <div className="biglogo">
                    <a href="https://cashfatafat.com" rel="noreferrer" target="_blank">
                        <img src={biglogo} alt=""/></a>
                </div>
                <div className="callbanner">
                    <p>
                        ☞ कमाए महा बम्पर धमाका के साथ लाखों बिलकुल देर ना करे अभी कॉल करे
                    </p>
                    <p>☞ Earn Lakhs With Big Bumper Dhamaka Don't delay call now</p>
                    <h4>+91-9831320333</h4>
                    <div className="twobuttons">
                        <button onClick={() => {
                            alert("You can call us on this number +91-9831320333")
                        }}>Call now
                        </button>
                        <button onClick={() => {
                            alert("You can use save this number +91-9831320333 to message us on What'sApp")
                        }}>Whatsapp now
                        </button>
                    </div>
                </div>
            </div>
            <div className="app_install">
                <a href="https://cashfatafat.com/#app" rel="noreferrer" target="_blank">
                    <button className="marquee_button">
                        <h3>📱 Install CashFatafat App Now</h3>
                    </button>
                </a>
            </div>
            {/*<div className="month">*/}
            {/*    <h2>December 2021 chart</h2>*/}
            {/*</div>*/}
            {num.length ? num.map((element) => {
                return (<div className="all_table">
                    <div className="alltable__title">
                        <h3>{element.title}</h3>
                    </div>
                    <Table striped bordered hover size="sm" responsive="sm">
                        <thead>
                        <tr>
                            <th>Time</th>
                            {element.data.length ? element.data.map((element2) => {
                                return <th key={element2.id}>
                                    {element2.start_time} - {element2.end_time}
                                </th>
                            }) : null}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Lucky Number</td>
                            {element.data.length ? element.data.map((element2) => {
                                return <td key={element2.id}>{element2.lucky_number} </td>
                            }) : null}
                        </tr>
                        </tbody>
                    </Table>
                </div>);

            }) : null}
            <div className="timetable__rules">
                <h2>CashFatafat Results Time Table</h2>
                <p>
                    CashFatafat game is the biggest and most played game all over the
                    country. This game is famous among various set states of the country.
                    Millions of people play the game and earn a pre-specified reward. If
                    you become successful to guess the correct number then it means you
                    have won a big amount of money. If not, then you will loose all the
                    money. We only provide CashFatafat Result on this portal. This game
                    is played 8 times a day and also updates 8 times result. After
                    playing the game everyone who has invested their money will become
                    curious about their result. So they search for their result. And
                    this portal is the best one for your CashFatafat Result 2021-2022.
                </p>
                <p style={{fontWeight: "700"}}>We have all Licences with GST number for playing the game and Owner
                    of CashFatafat is FOXCONN INDIA.
                </p>
                <p>
                    You can play the game 8 times in a single day from Monday to
                    Sunday. The company publishes
                    <a href="https://cashfatafat.com" rel="noreferrer" target="_blank">
                        <span> CashFatafat ক্যাশ ফটাফট।</span>
                    </a> Online 8 times in a single day.
                    See the results online here.
                </p>

            </div>
            <div className="timetable">
                <Timetable/>
            </div>
            <div className="timetable__rules">
                <p style={{fontWeight: "700", fontSize: "14px", color: "red"}}>
                    Disclaimer: The user must be 18 or above & user will play at his own risk.
                    There is an Element of financial risk involved in this game & it may be addictive.
                </p>
            </div>
        </div>
        <Footer/>
    </>);
};

export default Home;
