import React from "react";


import {NavLink} from 'react-router-dom';
import "./Footer.css";

const Footer = () => {
  return (
    <div className="result__footer section__padding" >
      <div className="result__footer-links">
        <div className="result__footer-links_logo">
          <p>CashFatafat @All Rights Reserved</p>
        </div>
        <div className="result__footer-links_container">
          <p>
            <NavLink to="#">Facebook</NavLink>
          </p>
          <p>
            <NavLink to="#">Instagram</NavLink>
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default Footer;
