import React from "react";
import Table from "react-bootstrap/Table";
import './Timetable.css';

const Timetable = () => {
    return (
        <>
            <div className="timetable__title">
                <h3>CashFatafat Result Time Table</h3>
            </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Games</th>
                    <th>Time Slots</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1st Bazi</td>
                    <td>⏰ 09:00 AM</td>
                </tr>

                <tr>
                    <td>2nd Bazi</td>
                    <td>⏰ 10:30 AM</td>
                </tr>

                <tr>
                    <td>3rd Bazi</td>
                    <td>⏰ 12:00 AM</td>
                </tr>

                <tr>
                    <td>4th Bazi</td>
                    <td>⏰ 01:30 PM</td>
                </tr>

                <tr>
                    <td>5th Bazi</td>
                    <td>⏰ 03:00 PM</td>
                </tr>

                <tr>
                    <td>6th Bazi</td>
                    <td>⏰ 04:30 PM</td>
                </tr>

                <tr>
                    <td>7th Bazi</td>
                    <td>⏰ 06:00 PM</td>
                </tr>

                <tr>
                    <td>8th Bazi</td>
                    <td>⏰ 07:30 PM</td>
                </tr>
                </tbody>
            </Table>
        </>
    )
}


export default Timetable;
