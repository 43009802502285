export function convertTimeStamp(timestamp) {
    // The 0 there is the key, which sets the date to the epoch
    return new Date(timestamp * 1000);
}

export function isToday(someDate) {
    const today = new Date();
    const fetchedTime = new Date(someDate * 1000);

    return (
        fetchedTime.getDate() === today.getDate() &&
        fetchedTime.getMonth() === today.getMonth() &&
        fetchedTime.getFullYear() === today.getFullYear()
    );
}

export const isSameDay = (array) => {
    const result = [];

    for (let i = 0; i < array.length; i++) {
        const nestedArray = [];
        if (i < (array.length - 1)) {
            if (array[i].format_date === array[i + 1].format_date) {
                nestedArray.push(array[i]);
            }
        }

        result.push(nestedArray);
    }
    console.log(result)
    return result;
}

export function checkTwoDate(item1, item2) {
    return (
        item1.getDay() === item2.getDay()
    );
}

// Accepts the array and key
export const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        const updatedResult = [];

        if (convertTimeStamp(result[currentValue[key]]).getDate() === (convertTimeStamp(result[currentValue[key]])).getDate() || []) {
            updatedResult.push(
                currentValue
            );
        }

        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return updatedResult;
    }, {}); // empty object is the initial value for result object
};

