import React from "react";

import logo from "../../assests/logo.svg";
import {NavLink} from 'react-router-dom';
import "./Navbar.css";

const Navbar = () => {
  return (

<div className="result__navbar" >
<div className="result__navbar-links">
  <div className="result__navbar-links_logo">
    <img alt={""} src={logo} />
  </div>
  <div className="result__navbar-links_container">
    <p>
      <NavLink to="/">Home</NavLink>
    </p>
   
    <p>
      <NavLink to="/home/profile"><button>Agent login</button></NavLink>
    </p>
    
  </div>
</div>

</div>
  );
};

export default Navbar;
